<template>
  <div class="">
    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.PAYMENT_OPERATION") }}
      </h3>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper full">
          <base-input
              :label="`${$t('COMMON.TO_THE_ATTENTION_OF')} (*)`"
              :placeholder="$t('COMMON.FIRSTNAME_LASTNAME')"
              v-model="customer"
              type="text"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.customer"/>
        </div>
        <div class="form-wrapper full">
          <property-payment-inputs
              :transactions="transactions"
              @transactionsChanged="onTransactionChanged"
          />
        </div>

        <div class="form-wrapper full">
          <base-input
              :label="`${$t('PROPERTIES.TRANSACTION_DATE')} (*)`"
              :placeholder="$t('PROPERTIES.TRANSACTION_DATE')"
              v-model="transaction_date"
              type="number"
          >
            <flat-picker
                :placeholder="$t('PROPERTIES.SOLD_AT')"
                :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                maxDate: 'today',
                locale: $flatPickrLocale(),
                disable: [
                  function (date) {
                    return date.getDay() === 0;
                  },
                ],
              }"
                class="form-control datepicker"
                v-model="transaction_date"
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_date"/>
        </div>


        <div class="form-wrapper full">
          <base-input
              :label="`${$t('COMMON.NOTE')}`"
              :placeholder="$t('COMMON.NOTE')"
          >
            <textarea
                v-model="transaction_excerpt"
                :placeholder="$t('COMMON.NOTE')"
                class="form-control"
                rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_excerpt"/>
        </div>
      </form>

      <template slot="footer">
        <button
            type="submit"
            class="btn btn-primary"
            @click="handleSubmit"
            :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.SAVE") }}
        </button>

        <button
            type="button"
            class="btn btn-link ml-auto"
            @click="onToggleModal"
            :disabled="loading"
            :transactions="[]"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>

    <modal :show="showRefundModal" modal-classes="modal-secondary" size="lg">
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.REFUND_OPERATION") }}
      </h3>
      <h4 class="text-center mt-2">
        {{ $t("PROPERTY_TRANSACTIONS.REFUND_AMOUNT") }} :
        {{ $formatCurrency(refund?.amount) }}
      </h4>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper full">
          <base-input
              :label="`${$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')} (*)`"
              :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
              v-model="refund.method_payment"
              :required="true"
          >
            <el-select
                :required="true"
                :clearable="true"
                class="select-primary pagination-select"
                :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
                v-model="refund.method_payment"
            >
              <el-option
                  class="select-primary"
                  v-for="(item, key) in PAYMENT_METHODS"
                  :key="key"
                  :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
                  :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.method_payment"/>
        </div>
      </form>

      <template slot="footer">
        <button
            type="submit"
            class="btn btn-primary"
            @click="saveTransaction"
            :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.SAVE") }}
        </button>

        <button
            type="button"
            class="btn btn-link ml-auto"
            @click="onCloseRefundModal"
            :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Button, Option, Select} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import {mapGetters} from "vuex";
import {AUCTION_PAYMENTS} from "@/constants/auctions";
import PropertyPaymentInputs from "@/components/PropertyPaymentInputs.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {TRANSACTION_TYPE_PAYMENT, TRANSACTION_TYPE_REFUND, TRANSACTION_TYPES,} from "@/constants/properties";
import moment from "moment";

export default {
  name: "property-transaction-form",

  components: {
    ValidationError,
    PropertyPaymentInputs,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["property", "showModal", "refreshView", "formErrors"],

  data() {
    return {
      lastStep: {},
      transactions: [],
      loading: false,
      transaction: {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      },
      transaction_excerpt: this.property?.transaction_excerpt ?? null,
      customer: this.property?.customer ?? null,
      transaction_date: moment().format("YYYY-MM-DD"),
      refund: {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      },
      refund_payment_method: "",
      showRefundModal: false,
      TRANSACTION_TYPES,
      TRANSACTION_TYPE_REFUND,
      PAYMENT_METHODS: AUCTION_PAYMENTS,
    };
  },

  computed: {
    ...mapGetters("transactions", ["list"]),
  },

  methods: {
    async handleSubmit() {
      if (!this.transaction.amount) {
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTY_TRANSACTIONS.AMOUNT_IS_REQUIRED"),
        });
        return;
      }
      let amount = 0;
      if (this.transactions?.length) {
        for (let i = 0; i < this.transactions.length; i++) {
          amount += parseFloat(this.transactions?.[i]?.amount ? parseFloat(this.transactions?.[i]?.amount)?.toFixed(2) : 0)
        }
      }
      console.log("amount", amount);
      console.log("transactions", this.transactions);
      const total = this.property ? this.property.total?.toFixed(2) : 0;
      if (amount < total) {
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTY_TRANSACTIONS.AMOUNT_IS_LESS_THAN_X", {
            x: this.$formatCurrency(this.property?.total),
          }),
        });
        return;
      }
      if (amount - total) {
        this.showRefundModal = true;
        this.refund = {
          ...this.transaction,
          method_payment: this.refund?.method_payment ?? "",
          amount: amount - total,
          transaction_type: TRANSACTION_TYPE_REFUND,
        };
      } else {
        this.refund = {};
        await this.saveTransaction();
      }
    },

    onToggleModal() {
      this.transaction = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      };
      this.refund = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      };

      this.transaction_excerpt = null;
      this.customer = null;
      this.transaction_date = moment().format("YYYY-MM-DD");
      this.$emit("onToggleModal");
    },

    async saveTransaction() {
      this.loading = true;
      try {
        this.property.transactions = [
            ...this.transactions
        ];
        if (this.refund?.amount) {
          this.property.transactions.push(this.refund);
        }

        await this.$store.dispatch("properties/createTransaction", {
          ...this.property,
          customer: !this.customer ? "-" : this.customer,
          transaction_excerpt: this.transaction_excerpt ?? null,
          transaction_date:
              this.transaction_date ?? moment().format("YYYY-MM-DD"),
        });
        this.$notify({
          type: "success",
          message: this.$t("PROPERTY_TRANSACTIONS.TRANSACTION_SAVED"),
        });
        this.loading = false;
        this.refreshView();
        this.onResetForm();
      } catch (error) {
        this.onCloseRefundModal();
        if (error?.response?.status === 400) {
          await this.showRequestError(error);
        } else if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.setApiValidation(error.response.data.errors);
          this.loading = false;
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
        this.showRefundModal = false;
        this.loading = false;
        this.property.transactions = [];
      }
    },

    onCloseRefundModal() {
      this.showRefundModal = false;
    },

    onTransactionChanged(transactions) {
      this.transactions = transactions;
    },

    onResetForm() {
      this.onToggleModal();
      this.onCloseRefundModal();

      this.transaction = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      };
      this.refund = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      };

      this.transaction_excerpt = null;
      this.customer = null;
      this.transaction_date = moment().format("YYYY-MM-DD");
    },
  },

  mounted() {
  },

  watch: {
    transactions(values) {
      console.log("transactions", values);
    },
    property(values) {
      console.log("property", values);
    },

    showModal(values) {
      const transaction = this.property?.transactions?.[0];
      const refund = this.property?.transactions?.[1];
      this.transactions = this.property.transactions;
      this.transaction = {
        amount: transaction?.amount ?? null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
        method_payment: transaction?.method_payment ?? null,
      };
      this.customer = this.property?.customer ?? null;
      this.transaction_excerpt = this.property?.transaction_excerpt ?? "";
      this.transaction_date =
          this.property?.transaction_date ?? moment().format("YYYY-MM-DD");
      this.refund = {
        amount: refund?.amount ?? null,
        transaction_type: TRANSACTION_TYPE_REFUND,
        method_payment: refund?.method_payment ?? null,
      };
      this.refund_payment_method = "";
    },
  },
};
</script>
